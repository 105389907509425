import { lazy } from 'react'

const UserMaster = lazy(() => import('../../views/masters/userMaster'))
const Company = lazy(() => import('../../views/masters/company'))

const MasterRoutes = [  
  {
    element: <UserMaster />,
    path: '/masters/userMaster'
  },
  {
    element: <Company />,
    path: '/masters/company'
  }

]

export default MasterRoutes
