// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../../src/utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../../src/utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerParty
export const getLedgerParty = createAsyncThunk('Payment/getLedgerParty', async (params, thunkAPI) => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerParty`, params, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken()
        if (refresh) {
            if (Number(refresh) === invalidRefreshToken) {
                thunkAPI.dispatch(handleLogout())
            } else {
                thunkAPI.dispatch(getLedgerParty(params))
            }
        }
    }
    if (response1 && response1.data && response1.data.body) {
        return {
            ledgerPartyList: response1.data.body.ledgerPartyList
        }
    }
})

//getLedgerBookList Api
export const getLedgerBookList = createAsyncThunk('/AccountReport/getLedgerBookList', async (param, thunkAPI) => {  
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
    const response1 = await axios.post(`${api.api_url}/accountsReport/getLedgerBookList`, param, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getLedgerBookList(param)) // calling same function again getting refresh token
       }     
      }
    }
    if (response1 && response1.data && response1.data.body) {
        return {
          data: response1.data.body,
          statusFlag: response1.data.body.statusFlag     
        }
    } else {
        return { statusFlag:1 }
    }   
})

export const ledgerBookSlice = createSlice({
    name: 'ledgerBook',
    initialState: {
        ledgerPartyList:[],
        ledgerBookList: [],
        statusFlag: 0,
        message: '',
        vou_no: '',
        vou_date: ''
    },
    reducers: {
        handleStatusFlag: (state, action) => {
          state.statusFlag = action.payload
        },
        clearStoreData: (state) => {
            state.ledgerPartyList = []
            state.data = ''
        }
      },
    extraReducers: builder => {
        builder.addCase(getLedgerParty.fulfilled, (state, action) => {
            state.ledgerPartyList = action.payload.ledgerPartyList
            state.message = action.payload.message
        })
        builder.addCase(getLedgerBookList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.statusFlag = action.payload.statusFlag
          })
    }
})

export const {
    handleStatusFlag, clearStoreData
} = ledgerBookSlice.actions

export default ledgerBookSlice.reducer
