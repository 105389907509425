export const api = {
  // api_url: 'http://192.168.1.222:8081/api'
 //api_url: 'http://172.16.1.250:7778/api'

  // api_url: 'https://ssmobileapi.trio-s.com/api'
 api_url:'https://nccaccapi.trio-s.com/api'
// api_url: 'http://localhost:8081/api'
}
export const Common_variable = {
    Save: 'Save',
    Update: 'Update',
    Save_continue: 'Save & Continue',
    Close: 'Close',
    Yes: 'Yes',
    No: 'No',
    Active: 'Active',
    Inactive: 'Inactive',
    Edit: 'Edit',
    Delete: 'Delete',
    Confirmation: 'Confirmation',
    Add: 'Add',
    Show: 'Show',
    Status:"Status",
    TransType:2,
    Regax_validation: /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/,
    characters_pattern: /^[a-zA-Z0-9]*$/,
    numbercharacters_pattern: /^[a-zA-Z0-9-@._ ]*$/,
    itemname_pattern: /^[a-zA-Z0-9-@._+ ]*$/,
    numberpattern: /^[0-9]*$/,
    numberdotpattern: /^[0-9.]*$/,
    numberdotnegativepattern: /^[0-9-.]*$/,
    specialcharacters_pattern: /^[a-zA-Z-@._ ]*$/,
    onlycharacters: /^[a-zA-Z0-9-_]*$/,
    emailValidation:  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    panCardValidation: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
    gstinValidation: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    India_Rupee_Formatter_rupees : (value) => new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value),
    India_Rupee_Formatter : (value) => new Intl.NumberFormat('en-IN', {
      currency: 'INR'
    }).format(value),
    India_Rupee_Formatter_Digits : (value) => new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2
    }).format(value)
    
} 
  // USER MESSAGE
export const USER = {
    EMPLOYEENAME_ERROR: 'Please enter employee name',
    USERNAME_ERROR: 'Please enter user name',
    EMPLOYEENAMETAMIL_ERROR: 'Please enter employee name in tamil',
    PASSWORD_ERROR: 'Please enter password',
    USERROLE_ERROR: 'Please select user role',
    BRANCHNAME_ERROR: 'Please select branch name',
    NEWPASSWORD_ERROR: 'Please enter password',
    CONFORMPASSWORD_ERROR: 'Please enter confrim password',
    EQUALCONFORMPASSWORD_ERROR: 'password and conform password dose not match',
    NAME_VALIDATION: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
    USERNAME_VALIDATION: /^[a-zA-Z0-9]+$/,
    EMPLOYEENAME_VALIDATIONERROR: 'Please enter valid employee name',
    USERNAME_VALIDATIONERROR: 'Please enter valid user name',
    PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
    PASSWORD_VALIDATIONERROR: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    DEVICEID_ERROR: 'Please enter device ID',
    PASSCODE_ERROR: 'Passcode should be 4 characters'
  }

  export const REASON = {
    REASON_ERROR: 'Please enter reason'
  }

export const LEDGERGROUP = {
  LEDGERGROUP: 'Please enter ledger group',
  ACCOUNTTYPE: 'Please select account type'
}

  // SALES MESSAGE
export const SALES = {
  CUSTOMER_ERROR: 'Please enter customer name',
  CUSTOMER_VALIDATIONERROR: 'Please enter valid customer name',
  SELLING_PRICE: 'Please eneter selling price',
  SELLING_PRICE_VALIDATIONERROR: 'Please enter valid selling price',
  IMEI_NO_ERROR: 'Please select imei number',
  SALES_PRODUCT: 'Please add atleast one product',
  LEDGER_PARTY_ERROR: 'Please select the customer'
}

export const COMPANY = {
  COMPANY_ERROR: 'Please enter company name',
  ADDRESS_ERROR: 'Please enter address',
  PH_ERROR: 'Please enter phone No.',
  GSTIN_ERROR: 'Please enter GSTIN',
  COMPANY_VALIDATIONERROR: 'Please enter valid company name',
  ADDRESS_VALIDATIONERROR: 'Please enter valid address'
}

export const ACCOUNTS = {
  LEDGER_ERROR: 'Please enter ledger name',
  LEDGER_VALIDATION_ERROR: 'Please enter valid ledger name',
  LEDGER_NAME_VALIDATION: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
  LEDGER_GROUP_ERROR: 'Please select ledger group',
  LEDGER_GSTIN_ERROR: 'Please enter valid GSTIN Number',
  LEDGER_PAN_ERROR: 'Please enter valid PAN Number',
  LEDGER_EMAIL_ERROR: 'Please enter valid email ID',
  PAYMENT_PAYMENTMODE_ERROR: 'Please select payment mode',
  PAYMENT_PAIDTO_ERROR: 'Please select paid to',
  PAYMENT_AMOUNT_ERROR: 'Please enter amount',
  CONTRA_FROM_LEDGER_ERROR: 'Please select from ledger',
  CONTRA_TO_LEDGER_ERROR: 'Please select to ledger',
  CONTRA_AMOUNT_ERROR: 'Please enter amount',
  CONTRA_FROM_TO_SAME_LEDGER_ERROR: 'From ledger and To ledger should not be same',
  RECEIPT_RECEIPTMODE_ERROR: 'Please select receipt mode',
  RECEIPT_RECEIVEDFROM_ERROR: 'Please select received from',
  RECEIPT_AMOUNT_ERROR: 'Please enter amount'
}

export const stockInwardType = {
  reset: 2,
  inward: 1
}

//AccountType master data
export const AccountTypeMaster = [
      { label: 'Income', value: 1 },
      { label: 'Asset', value: 2 },
      { label: 'Expense', value: 3 },
      { label: 'Liability', value: 4 },
      { label: 'Cash', value: 5 },
      { label: 'Bank', value: 6 }
  ]

export const invalidRefreshToken = 498

export const PaymentType = {
  cash: 1,
  credit: 2
}

export const statusmasterList = [
  { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
]
