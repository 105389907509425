import { lazy } from 'react'

const PendingReport = lazy(() => import('../../views/transaction/pendingReport'))

const pendingReportRoutes = [
  {
    element: <PendingReport />,
    path: '/transaction/pendingReport'
  }

]

export default pendingReportRoutes
