import { lazy } from 'react'

const MenuAccess = lazy(() => import('../../views/transaction/menuAccess'))

const menuAccessRoutes = [
  {
    element: <MenuAccess/>,
    path: '/transaction/menuAccess'
  }

]

export default menuAccessRoutes