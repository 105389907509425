// ** Redux Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../utility/commonfunc'
import { handleLogout } from '@store/authentication'


// fetchIMEIData Api
export const fetchIMEIData = createAsyncThunk('ProductCancel/fetchIMEIData', async (userId, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response = await axios.post(`${api.api_url}/master/getIMEIData`, { user_id: userId }, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(fetchIMEIData(userId)) // calling same function again getting refresh token
       }
      }
    }    
    if (response && response.data && response.data.body) {
      return {
        imeiList: response.data.body.imei_list,
        statusFlag: response.data.body.statusFlag
      }
    } else {
      return { statusFlag: 1 }
    }
  } catch (error) {
    console.error('Error fetching IMEI data:', error)
    throw error
  }
})

//getMaster Data
export const getmaster = createAsyncThunk('ProductCancel/getmaster', async (params, thunkAPI) => {
  try {
    const user = getUserData()
    const config = {headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response1 = await axios.post(`${api.api_url}/master/getMasterData`, params, config) 
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getmaster(params)) // calling same function again getting refresh token
       }
      }
    }     
    if (response1 && response1.data && response1.data.body) {
      return {
        userroleList: response1.data.body.userroleList,
        reasonList: response1.data.body.reasonList
      }
    }
  } catch (error) {
    console.error('Error fetching master data:', error)
    throw error
  }
})

//save cancel product
export const Productcancelled = createAsyncThunk('ProductCancel/cancelProduct', async ({ user_id, imei_no, reason_code, remarks, total_amount, fy }, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const requestData = {
      user_id,
      imei_no,
      reason_code,
      remarks,
      total_amount,
      fy
    }
    const response = await axios.post(`${api.api_url}/product/saveCancelledProduct`, requestData, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(Productcancelled({ user_id, imei_no, reason_code, remarks, total_amount, fy })) // calling same function again getting refresh token
       }
      }
    } 
    if (response && response.data && response.data.body) {
      return {
        statusFlag: response.data.body.statusFlag,
        message: response.data.body.message
      }
    } else {
      return { statusFlag: 0, message: 'Failed to create Cancelled Product' }
    }
  } catch (error) {
    console.error('Error creating Cancelled Product:', error)
    throw error
  }
})


//getCancelledProductList
export const getCancelledProductList = createAsyncThunk('ProductCancel/getCancelledProductList', async (requestData, thunkAPI) => {
  try {
    const user = getUserData()
    const config = {headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response = await axios.post(`${api.api_url}/product/getCancelledProductList`, requestData, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getCancelledProductList(requestData)) // calling same function again getting refresh token
       }
      }
    }     
    return {
      cancelledProducts: response.data.body.cancelledProducts,
      statusFlag: response.data.body.statusFlag
    }
  } catch (error) {
    console.error('Error retrieving Another List:', error)
    throw error
  }
})

//deleteCancelledProduct
export const deleteCancelledProduct = createAsyncThunk('ProductCancel/deleteCancelledProduct', async (requestData, thunkAPI) => {
  try {
    const user = getUserData()
    const config = {headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response = await axios.post(`${api.api_url}/product/deleteCancelledProduct`, requestData, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(deleteCancelledProduct(requestData)) // calling same function again getting refresh token
       }
      }
    }     
    return {
      statusFlag: response.data.body.statusFlag,
      message: response.data.body.message
    }
  } catch (error) {
    console.error('Error retrieving delete List:', error)
    throw error
  }
})

//getIMEIDetails
export const getIMEIDetails = createAsyncThunk('ProductCancel/getIMEIDetails', async (requestData, thunkAPI) => {
  try {
    const user = getUserData()
    const config = {headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response = await axios.post(`${api.api_url}/inward/getIMEIDetails`, requestData, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getIMEIDetails(requestData)) // calling same function again getting refresh token
       }
      }
    }  
    return {
      statusFlag: response.data.body.statusFlag,
      imei_details: response.data.body.imei_list
    }
  } catch (error) {
    console.error('Error retrieving delete List:', error)
    throw error
  }
})

  export const ProductCancel = createSlice({
    name: 'ProductCancel',
    initialState: {
      message:'',
      params: {},
      statusFlag:0,
      transactionDetails: []
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload
      }     
    },
    extraReducers: builder => {      
      builder.addCase(Productcancelled.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      }) 
      builder.addCase(getCancelledProductList.fulfilled, (state, action) => {
        state.cancelledProducts = action.payload.cancelledProducts
        state.statusFlag = action.payload.statusFlag
      }) 
      builder.addCase(fetchIMEIData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.imei_list = action.payload.imeiList
      })  
      builder.addCase(deleteCancelledProduct.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })    
      builder.addCase(getmaster.fulfilled, (state, action) => {
        state.userroleList = action.payload.userroleList
        state.reasonList = action.payload.reasonList
      })   
      builder.addCase(getIMEIDetails.fulfilled, (state, action) => {
        state.imei_details = action.payload.imei_details
        state.statusFlag = action.payload.statusFlag
      })    
    }
  })

  export const {
    handleStatusFlag
  } = ProductCancel.actions
  
export default ProductCancel.reducer
  