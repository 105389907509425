// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'


// saveimeidetails API
export const saveimeidetails = createAsyncThunk('Inward/getInwardNotProcessedList', async params => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/inward/getInwardNotProcessedList`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(saveimeidetails(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      imei_details: response1.data.body.imei_list
    }
  }
})

//deleteInward Api 
export const deleteInward = createAsyncThunk('Inward/insertInwardDelete', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/inward/insertInwardDelete`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(deleteInward(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag: response1.data.body.statusFlag,
      message: response1.data.body.message
    }
  }
})

export const inwardSlice = createSlice({
  name: 'inwardDelete',
  initialState: {
    imei_details: [],
    statusFlag: 0,
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveimeidetails.fulfilled, (state, action) => {
        state.imei_details = action.payload.imei_details
      })
    builder
      .addCase(deleteInward.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
  }
})
export const {
  handleStatusFlag
} = inwardSlice.actions

export default inwardSlice.reducer