import { lazy } from 'react'

const MobileView = lazy(() => import('../../views/transaction/mobileView'))

const mobileRoutes = [
  {
    element: <MobileView/>,
    path: '/transaction/mobileView'
  }

]

export default mobileRoutes