import { lazy } from 'react'

const LedgerMaster = lazy(() => import('../../views/accounts/masters/ledgerMaster'))
const LedgerGroupMaster = lazy(() => import('../../views/accounts/masters/ledgerGroupMaster'))
const Payment = lazy(() => import('../../views/accounts/payment'))
const Receipt = lazy(() => import('../../views/accounts/receipt'))
const Contra = lazy(() => import('../../views/accounts/contra'))
const DayBookReport = lazy(() => import('../../views/accounts/reports/dayBook'))
const LedgerBookReport = lazy(() => import('../../views/accounts/reports/ledgerBook'))
const CashBankReport = lazy(() => import('../../views/accounts/reports/cashBankReport'))
const PayableReport = lazy(() => import('../../views/accounts/reports/payableReport'))
const ReceivableReport = lazy(() => import('../../views/accounts/reports/receivableReport'))

const AccountRoutes = [  
  {
    element: <LedgerMaster />,
    path: '/accounts/ledgerMaster'
  },
  {
    element: <LedgerGroupMaster />,
    path: '/accounts/ledgerGroup'
  },
  {
    element: <Payment />,
    path: '/accounts/payment'
  },
  {
    element: <Receipt />,
    path: '/accounts/receipt'
  },
  {
    element: <Contra />,
    path: '/accounts/contra'
  },
  {
    element: <DayBookReport />,
    path: '/accounts/reports/dayBook'
  },
  {
    element: <LedgerBookReport />,
    path: '/accounts/reports/ledgerBook'
  },
  {
    element: <CashBankReport />,
    path: '/accounts/reports/cashBankReport'
  },
  {
    element: <PayableReport />,
    path: '/accounts/reports/payableReport'
  },
  {
    element: <ReceivableReport />,
    path: '/accounts/reports/receivableReport'
  }

]

export default AccountRoutes
