// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants' 
import { getUserData } from '@utils'
import { getRefreshToken } from '../../../../utility/commonfunc'
import { handleLogout } from '@store/authentication'

export const menu = createAsyncThunk('master/getMenus', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/master/getMenus`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(menu(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      menu_list:response1.data.body.menu_list
    }
  } 
})

export const insertMenuAccess = createAsyncThunk('master/insertMenuAccess', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/master/insertMenuAccess`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(insertMenuAccess(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

export const menuchecked = createAsyncThunk('master/getMenuAccess', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/master/getMenuAccess`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(menuchecked(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      menucheck:response1.data.body.menu_list
    }
  } 
})

export const menuAccess = createSlice({
    name: 'menuAccess',
    initialState: {
      message:'',
      params: {},
      statusFlag:0,
      menu_list: []
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload
      }     
    },
    extraReducers: builder => {      
      builder.addCase(menu.fulfilled, (state, action) => {
       state.menu_list = action.payload.menu_list
      }) 
      builder.addCase(insertMenuAccess.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      }) 
      builder.addCase(menuchecked.fulfilled, (state, action) => {
        state.menucheck = action.payload.menucheck
       }) 
  }
  })

  export const {
    handleStatusFlag
  } = menuAccess.actions
  
export default menuAccess.reducer

