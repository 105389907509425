// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../../src/utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../../src/utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getDayBookAccount Api
export const getDayBookAccount = createAsyncThunk('/AccountReport/getDayBookList', async (param, thunkAPI) => {  
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
    const response1 = await axios.post(`${api.api_url}/accountsReport/getDayBookList`, param, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getDayBookAccount(param)) // calling same function again getting refresh token
       }     
      }
    }
    if (response1 && response1.data && response1.data.body) {
        return {
          data: response1.data.body,
          statusFlag: response1.data.body.statusFlag     
        }
    } else {
        return { statusFlag:1 }
    }  
  } catch (error) {
    console.error('Error In DayBookAccountList', error)
  }
 
})

export const DayBookSlice = createSlice({
    name: 'DayBook',
    initialState: {
      data: [],
      statusFlag:0
    },
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload
      }
    },
    extraReducers: builder => {
      builder.addCase(getDayBookAccount.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.statusFlag = action.payload.statusFlag
      })
    }
  })

export const {
    handleStatusFlag
  } = DayBookSlice.actions
  
  export default DayBookSlice.reducer