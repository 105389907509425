import { lazy } from 'react'

const PurchaseTax = lazy(() => import('../../views/transaction/purchaseTax'))

const purchaseTaxRoutes = [
  {
    element: <PurchaseTax />,
    path: '/transaction/purchaseTax'
  }

]

export default purchaseTaxRoutes
