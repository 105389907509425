import { lazy } from 'react'

const SalesLog = lazy(() => import('../../views/transaction/salesLog'))

const salesLogRoutes = [
  {
    element: <SalesLog />,
    path: '/transaction/salesLog'
  }

]

export default salesLogRoutes
