// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants' 
import { getUserData } from '@utils'
import { getRefreshToken } from '../../../../utility/commonfunc'
import { handleLogout } from '@store/authentication'


// getProfitReport Api
export const getProfitReport = createAsyncThunk('profitReport/getProfitReport', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/report/getProfitReport`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getProfitReport(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      transactionDetails:response1.data.body.transactionDetails
    }
  } 
})

export const saveCustomerdetails = createAsyncThunk('Sales/getCustomerList', async params => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/Sales/getCustomerList`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(saveCustomerdetails(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      customer_list: response1.data.body.customer_list
    }
  }
})

export const profitReport = createSlice({
  name: 'profitReport',
    initialState: {
      message:'',
      params: {},
      statusFlag:0,
      transactionDetails: []
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload
      }     
    },
    extraReducers: builder => {      
      builder.addCase(getProfitReport.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.transactionDetails = action.payload.transactionDetails
      }) 
      builder
        .addCase(saveCustomerdetails.fulfilled, (state, action) => {
          state.customer_list = action.payload.customer_list
        })
    }
  })

  export const {
    handleStatusFlag
  } = profitReport.actions
  
export default profitReport.reducer

