// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerMode
export const getLedgerMode = createAsyncThunk('Receipt/getLedgerMode', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerMode`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerMode(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerModeList: response1.data.body.ledgerModeList     
        }
    }
})

//getLedgerParty
export const getLedgerParty = createAsyncThunk('Receipt/getLedgerParty', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerParty`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerParty(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerPartyList: response1.data.body.ledgerPartyList     
        }
    }
})

//getReceiptVouNo
export const getReceiptVouNo = createAsyncThunk('Receipt/getReceiptVouNo', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/receipt/getReceiptVouNo`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getReceiptVouNo(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          vou_no: response1.data.body.vou_no,
          vou_date: response1.data.body.vou_date,
          statusFlag: 1    
        }
    }
})

//getReceipt
export const getReceipt = createAsyncThunk('Receipt/getReceipt', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/receipt/getReceipt`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getReceipt(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          receiptList: response1.data.body.receiptList,
          statusFlag:  response1.data.body.statusFlag  
        }
    }
})


// insertReceipt Api
export const insertReceipt = createAsyncThunk('Receipt/insertReceipt', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/receipt/insertReceipt`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(insertReceipt(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// updateReceipt Api
export const updateReceipt = createAsyncThunk('Receipt/updateReceipt', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/receipt/updateReceipt`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(updateReceipt(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// deleteReceipt Api
export const deleteReceipt = createAsyncThunk('Receipt/deleteReceipt', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/receipt/deleteReceipt`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(deleteReceipt(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// getReceiptEditLoad Api
export const getReceiptEditLoad = createAsyncThunk('Receipt/getReceiptEditLoad', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/receipt/getReceiptEditLoad`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getReceiptEditLoad(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      receiptDetails:response1.data.body.receiptList
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_ReceiptMode = createAsyncThunk('Receipt/getLedgerClosingBalance_ReceiptMode', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_receiptmode:response1.data.body.ledgerClosing
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_ReceivedFrom = createAsyncThunk('Receipt/getLedgerClosingBalance_ReceivedFrom', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_receivedfrom:response1.data.body.ledgerClosing
    }
  } 
})

export const receiptSlice = createSlice({
  name: 'Receipt',
  initialState: {
    ledgerModeList:[],
    ledgerPartyList:[],
    statusFlag:0,
    message:'',
    receiptList: [],
    vou_no: '',
    vou_date: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    },
    handleVocucherData: (state) => {
      state.vou_no = ''
      state.vou_date = ''
      state.receiptDetails = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getLedgerMode.fulfilled, (state, action) => {
      state.ledgerModeList = action.payload.ledgerModeList     
      state.message = action.payload.message
    })
    builder.addCase(getLedgerParty.fulfilled, (state, action) => {
      state.ledgerPartyList = action.payload.ledgerPartyList     
      state.message = action.payload.message
    })
    builder.addCase(getReceiptVouNo.fulfilled, (state, action) => {
      state.vou_date = action.payload.vou_date     
      state.vou_no = action.payload.vou_no
      state.statusFlag = action.payload.statusFlag
    })
    builder.addCase(getReceipt.fulfilled, (state, action) => {
      state.receiptList = action.payload.receiptList     
      state.statusFlag = action.payload.statusFlag
    })
    .addCase(insertReceipt.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })    
    .addCase(updateReceipt.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteReceipt.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(getReceiptEditLoad.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.receiptDetails = action.payload.receiptDetails
     state.statusFlag = action.payload.statusFlag
     state.ledgerClosing_receiptmode = ''
     state.ledgerClosing_receivedfrom = ''
    })
    .addCase(getLedgerClosingBalance_ReceiptMode.fulfilled, (state, action) => {
     state.ledgerClosing_receiptmode = action.payload.ledgerClosing_receiptmode
    })
    .addCase(getLedgerClosingBalance_ReceivedFrom.fulfilled, (state, action) => {
      state.ledgerClosing_receivedfrom = action.payload.ledgerClosing_receivedfrom
     })
    
  }
})

export const {
  handleStatusFlag, handleVocucherData
} = receiptSlice.actions

export default receiptSlice.reducer
