// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'


// getstockList API
export const stockListapi = createAsyncThunk('report/getStockList', async params => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response1 = await axios.post(`${api.api_url}/report/getStockList`, params, config) 
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(stockListapi(params)) // calling same function again getting refresh token
       }
      }
    } 
     if (response1 && response1.data && response1.data.body) {
      console.log('res', response1.data.body)
          return {
            stockList: response1.data.body.stockList,
            openingstock: response1.data.body.openingstock,
            closingstock: response1.data.body.closingstock   
          }
      }
  })

export const stockListSlice = createSlice({
  name: 'stockList',
  initialState: {
    stockList: [],
    openingstock: [],
    closingstock: [],
    statusFlag:0, 
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(stockListapi.fulfilled, (state, action) => {
        state.stockList = action.payload.stockList   
        state.openingstock = action.payload.openingstock   
        state.closingstock = action.payload.closingstock   
    })
     }
})
export const {
  handleStatusFlag
} = stockListSlice.actions

export default stockListSlice.reducer