// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

// getimeidetails API
export const salesLog = createAsyncThunk('report/getSalesLogList', async params => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response1 = await axios.post(`${api.api_url}/report/getSalesLogList`, params, config)  
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(salesLog(params)) // calling same function again getting refresh token
       }
      }
    }
     if (response1 && response1.data && response1.data.body) {
          return {
            salesList: response1.data.body.salesList   
          }
      }
  })

export const salesLogSlice = createSlice({
  name: 'salesLog',
  initialState: {
    salesList: [],
    statusFlag:0, 
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(salesLog.fulfilled, (state, action) => {
        state.salesList = action.payload.salesList   
    })
     }
})
export const {
  handleStatusFlag
} = salesLogSlice.actions

export default salesLogSlice.reducer