// ** Icons Import
import { FileText } from 'react-feather'

export default [
  {
    id: 'salesreturn',
    title: 'Sales Return ',
    icon: <FileText size={20} />,
    navLink: 'transaction/salesReturn'
  }
]
