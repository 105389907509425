

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

// saveReason API
export const saveReason = createAsyncThunk('Reason/saveReason', async ({ user_id, reason }) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
const response1 = await axios.post(`${api.api_url}/reason/saveReason`, { user_id, reason }, config)
if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
  const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
 if (refresh) {
  if (Number(refresh) === invalidRefreshToken) {
    thunkAPI.dispatch(handleLogout())
   } else { 
    thunkAPI.dispatch(saveReason(params)) // calling same function again getting refresh token
   }
  }
}
    return response1.data.body
  } catch (error) {
   console.error('Error saving reason:', error)
    throw error
  }
})

//getreasonlist
export const getReason = createAsyncThunk('Reason/getReason', async params => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response1 = await axios.post(`${api.api_url}/reason/getReasonList`, params, config) 
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getReason(params)) // calling same function again getting refresh token
       }
      }
    } 
     if (response1 && response1.data && response1.data.body) {
          return {
            reasonList: response1.data.body.reasonList,
            statusFlag: 1   
          }
      }
  })

//deletereeasonlist api
  export const deleteReason = createAsyncThunk('Reason/deleteReason', async ({ user_id, reason_id }) => {
    try {
      const user = getUserData()
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(`${api.api_url}/reason/deleteReason`, { user_id, reason_id }, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(deleteReason(params)) // calling same function again getting refresh token
     }
    }
  }
      return response1.data.body
    } catch (error) {
     console.error('Error saving reason:', error)
      throw error
    }
  })

//updatereason api
  export const updateReason = createAsyncThunk('Reason/updateReason', async ({ user_id, reason, reason_id, status }) => {
    try {
      const user = getUserData()
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
      const response1 = await axios.post(`${api.api_url}/reason/updateReason`, { user_id, reason, reason_id, status }, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(updateReason(params)) // calling same function again getting refresh token
     }
    }
  }
      return response1.data.body
    } catch (error) {
     console.error('Error saving reason:', error)
      throw error
    }
  })

export const ReasonSlice = createSlice({
  name: 'Reason',
  initialState: {
    data: [],
    statusFlag:0, 
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveReason.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(getReason.fulfilled, (state, action) => {
        state.reasonList = action.payload.reasonList
        state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteReason.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(updateReason.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
     }
})
export const {
  handleStatusFlag
} = ReasonSlice.actions

export default ReasonSlice.reducer