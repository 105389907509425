// ** Navigation imports 

import forms from './forms'
import inward from './inward'
import Sales from './sales'
import SalesReturn from './salesReturn'
import Stock from './stock'
import mobileView from './mobileView'
import masters from './masters'
import accounts from './Account'

// ** Merge & Export

// import Masters from './masters'

// ** Merge & Export
const getMenuData = JSON.parse(localStorage.getItem('menuDetails'))
const getmenus = getMenuData ? getMenuData.flatMap(item => item.menu_id) : []
const masterArray = [2, 3, 4, 15, 16]
const transArray = [5, 6]
const reportArray = [9, 10, 11, 12, 13, 20, 21, 22, 23, 24]
const vouchersArray = [17, 18, 19]
// const accountsArray = []
let menus = []
// menus = [...forms, ...masters, ...inward, ...mobileView, ...Sales, ...Stock]
if (getmenus.find(item => item === 1)) {
    localStorage.setItem('defaultNav', '/process')
    menus = [...forms]
}
if (masterArray.some(value => getmenus.some(item => item === value))) {
    menus = [...menus, ...masters]
}
if (vouchersArray.some(value => getmenus.some(item => item === value))) {
    menus = [...menus, ...accounts]
}
if (transArray.some(value => getmenus.some(item => item === value))) {
    menus = [...menus,  ...inward]
}
if (getmenus.find(item => item === 7)) {
    localStorage.setItem('defaultNav', '/transaction/mobileView')
    menus = [...menus, ...mobileView]
}
if (getmenus.find(item => item === 8)) {
    menus = [...menus, ...Sales, ...SalesReturn]
}
if (reportArray.some(value => getmenus.some(item => item === value))) {
    menus = [...menus, ...Stock]
}

export default menus
