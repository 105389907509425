// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerMode
export const getLedgerMode = createAsyncThunk('Contra/getLedgerMode', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerMode`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerMode(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerModeList: response1.data.body.ledgerModeList     
        }
    }
})

//getLedgerParty
export const getLedgerParty = createAsyncThunk('Contra/getLedgerParty', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerParty`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerParty(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerPartyList: response1.data.body.ledgerPartyList     
        }
    }
})

//getContraVouNo
export const getContraVouNo = createAsyncThunk('Contra/getContraVouNo', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/contra/getContraVouNo`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getContraVouNo(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          vou_no: response1.data.body.vou_no,
          vou_date: response1.data.body.vou_date,
          statusFlag: 1    
        }
    }
})

//getContra
export const getContra = createAsyncThunk('Contra/getContra', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/contra/getContra`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getContra(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          contraList: response1.data.body.contraList,
          statusFlag:  response1.data.body.statusFlag  
        }
    }
})


// insertContra Api
export const insertContra = createAsyncThunk('Contra/insertContra', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/contra/insertContra`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(insertContra(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// updateContra Api
export const updateContra = createAsyncThunk('Contra/updateContra', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/contra/updateContra`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(updateContra(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// deleteContra Api
export const deleteContra = createAsyncThunk('Contra/deleteContra', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/contra/deleteContra`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(deleteContra(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// getContraEditLoad Api
export const getContraEditLoad = createAsyncThunk('Contra/getContraEditLoad', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/contra/getContraEditLoad`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getContraEditLoad(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      contraDetails:response1.data.body.contraList
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_FromLedger = createAsyncThunk('Contra/getLedgerClosingBalance_FromLedger', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_fromledger:response1.data.body.ledgerClosing
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_ToLedger = createAsyncThunk('Contra/getLedgerClosingBalance_ToLedger', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_toledger:response1.data.body.ledgerClosing
    }
  } 
})

export const contraSlice = createSlice({
  name: 'Contra',
  initialState: {
    ledgerModeList:[],
    ledgerPartyList:[],
    statusFlag:0,
    message:'',
    contraList: [],
    vou_no: '',
    vou_date: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    },
    handleVocucherData: (state) => {
      state.vou_no = ''
      state.vou_date = ''
      state.contraDetails = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getLedgerMode.fulfilled, (state, action) => {
      state.ledgerModeList = action.payload.ledgerModeList     
      state.message = action.payload.message
    })
    builder.addCase(getLedgerParty.fulfilled, (state, action) => {
      state.ledgerPartyList = action.payload.ledgerPartyList     
      state.message = action.payload.message
    })
    builder.addCase(getContraVouNo.fulfilled, (state, action) => {
      state.vou_date = action.payload.vou_date     
      state.vou_no = action.payload.vou_no
      state.statusFlag = action.payload.statusFlag
    })
    builder.addCase(getContra.fulfilled, (state, action) => {
      state.contraList = action.payload.contraList     
      state.statusFlag = action.payload.statusFlag
    })
    .addCase(insertContra.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })    
    .addCase(updateContra.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteContra.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(getContraEditLoad.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.contraDetails = action.payload.contraDetails
     state.statusFlag = action.payload.statusFlag
     state.ledgerClosing_fromledger = ''
     state.ledgerClosing_toledger = ''
    })
    .addCase(getLedgerClosingBalance_FromLedger.fulfilled, (state, action) => {
     state.ledgerClosing_fromledger = action.payload.ledgerClosing_fromledger
    })
    .addCase(getLedgerClosingBalance_ToLedger.fulfilled, (state, action) => {
      state.ledgerClosing_toledger = action.payload.ledgerClosing_toledger
     })
    
  }
})

export const {
  handleStatusFlag, handleVocucherData
} = contraSlice.actions

export default contraSlice.reducer
