// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants' 
import { getUserData } from '@utils'
import { getRefreshToken } from '../../../../utility/commonfunc'
import { handleLogout } from '@store/authentication'

// saveimeidetails API
export const saveimeidetails = createAsyncThunk('master/getAllIMEIData', async params => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/master/getAllIMEIData`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(saveimeidetails(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      imei_details: response1.data.body.imei_list
    }
  }
})


export const saveCustomerdetails = createAsyncThunk('Sales/getCustomerList', async params => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/Sales/getCustomerList`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(saveCustomerdetails(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      customer_list: response1.data.body.customer_list
    }
  }
})

// getSalesInvNo Api
export const getSalesInvNo = createAsyncThunk('Sales/getSalesInvNo', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/sales/getSalesInvNo`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getSalesInvNo(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      inv_no:response1.data.body.inv_no, 
      inv_date:response1.data.body.inv_date
    }
  } 
})


// fetchIMEIData Api
export const fetchIMEIData = createAsyncThunk('ProductCancel/fetchIMEIData', async (params, thunkAPI) => {
    try {
      const user = getUserData()
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
      const response = await axios.post(`${api.api_url}/master/getIMEIData`, params, config)
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
       if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
         } else {
          thunkAPI.dispatch(fetchIMEIData(params)) // calling same function again getting refresh token
         }
        }
      }    
      if (response && response.data && response.data.body) {
        return {
          imei_list: response.data.body.imei_list,
          statusFlag: response.data.body.statusFlag
        }
      } else {
        return { statusFlag: 1 }
      }
    } catch (error) {
      console.error('Error fetching IMEI data:', error)
      throw error
    }
  })
  // getLedgerParty Api
export const getLedgerParty = createAsyncThunk('Sales/getLedgerParty', async (params, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
    const response = await axios.post(`${api.api_url}/accountsMaster/getLedgerSales`, params, config)
    if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getLedgerParty(params)) // calling same function again getting refresh token
       }
      }
    }    
    if (response && response.data && response.data.body) {
      return {
        ledgerPartyList: response.data.body.ledgerPartyList     
      }
    } else {
      return { statusFlag: 1 }
    }
  } catch (error) {
    console.error('Error fetching ledger party data:', error)
    throw error
  }
})

// insertSales  Api
export const insertSales = createAsyncThunk('Sales/insertSales', async (params, thunkAPI) => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
    const response1 = await axios.post(`${api.api_url}/sales/insertSales`, params, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else { 
        thunkAPI.dispatch(insertSales(params)) // calling same function again getting refresh token
       }
      }
    }
    if (response1 && response1.data && response1.data.body) { 
      return { 
        statusFlag:response1.data.body.statusFlag, 
        message:response1.data.body.message
      }
    } 
  })


//getSalesList
export const getSalesList = createAsyncThunk('Sales/getSalesList', async (requestData, thunkAPI) => {
    try {
      const user = getUserData()
      const config = {headers: { authorization: 'Bearer '.concat(user.accessToken) }}
      const response = await axios.post(`${api.api_url}/sales/getSalesList`, requestData, config)
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
       if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
         } else {
          thunkAPI.dispatch(getSalesList(requestData)) // calling same function again getting refresh token
         }
        }
      }     
      return {
        salesList: response.data.body.salesList,
        statusFlag: response.data.body.statusFlag
      }
    } catch (error) {
      console.error('Error retrieving Another List:', error)
      throw error
    }
  })
  

  //deleteSales Api 
export const deleteSales = createAsyncThunk('Sales/deleteData', async (params, thunkAPI) => { 
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}       
     const response1 = await axios.post(`${api.api_url}/sales/deleteSales`, params, config)
     if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(deleteSales(params)) // calling same function again getting refresh token
       }
      }
    }
     if (response1 && response1.data && response1.data.body) { 
      return {     
        statusFlag:response1.data.body.statusFlag,      
        message:response1.data.body.message
      }   
    } 
  })

  //getSalesTransDetails Api 
  export const getSalesTransDetails = createAsyncThunk('Sales/getSalesTransDetails', async (params, thunkAPI) => { 
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}       
     const response1 = await axios.post(`${api.api_url}/sales/getSalesTransDetails`, params, config)
     if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getSalesTransDetails(params)) // calling same function again getting refresh token
       }
      }
    }
     if (response1 && response1.data && response1.data.body) { 
      return {     
        salesDetails:response1.data.body.salesDetails,      
        statusFlag:response1.data.body.statusFlag
      }   
    } 
  })

 //updateSales Api 
 export const updateSales = createAsyncThunk('Sales/updateSales', async (params, thunkAPI) => { 
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}       
   const response1 = await axios.post(`${api.api_url}/sales/updateSales`, params, config)
   if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(updateSales(params)) // calling same function again getting refresh token
     }
    }
  }
   if (response1 && response1.data && response1.data.body) { 
    return {     
      statusFlag:response1.data.body.statusFlag,      
      message:response1.data.body.message
    }   
  } 
})

export const Sales = createSlice({
    name: 'Sales',
    initialState: {
      message:'',
      params: {},
      statusFlag:0,
      imei_list: [],
      salesList: [],
      salesDetails: [],
      ledgerPartyList:[]
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload
      },
      editSalesInvoiceDetails: (state, action) => {
        state.editSalesInvoiceDetails = action.payload
        state.salesDetails = ''
      }     
    },
    extraReducers: builder => {      
      builder.addCase(getSalesInvNo.fulfilled, (state, action) => {
        state.inv_no = action.payload.inv_no
        state.inv_date = action.payload.inv_date
      }) 
      builder.addCase(fetchIMEIData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.imei_list = action.payload.imei_list
      }) 
      builder.addCase(insertSales.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })  
      builder.addCase(getSalesList.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.salesList = action.payload.salesList
      })  
      builder.addCase(deleteSales.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })  
      builder.addCase(getSalesTransDetails.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.salesDetails = action.payload.salesDetails
      }) 
      builder.addCase(updateSales.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })  
      builder.addCase(getLedgerParty.fulfilled, (state, action) => {
        state.ledgerPartyList = action.payload.ledgerPartyList     
        state.message = action.payload.message
      })
      builder
        .addCase(saveimeidetails.fulfilled, (state, action) => {
          state.imei_details = action.payload.imei_details
      })
      builder
        .addCase(saveCustomerdetails.fulfilled, (state, action) => {
          state.customer_list = action.payload.customer_list
        })
    }
  })

  export const {
    handleStatusFlag, editSalesInvoiceDetails
  } = Sales.actions
  
export default Sales.reducer

