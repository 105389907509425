import { lazy } from 'react'

const SalesReturn = lazy(() => import('../../views/transaction/salesReturn'))
const AddSalesReturn = lazy(() => import('../../views/transaction/salesReturn/AddInvoice'))

const SalesReturnRoutes = [
  {
    element: <SalesReturn />,
    path: '/transaction/salesReturn'
  },
  {
    element: <AddSalesReturn />,
    path: '/transaction/salesReturn/add'
  }
  
]

export default SalesReturnRoutes
