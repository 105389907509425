// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerMode
export const getLedgerMode = createAsyncThunk('Payment/getLedgerMode', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerMode`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerMode(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerModeList: response1.data.body.ledgerModeList     
        }
    }
})

//getLedgerParty
export const getLedgerParty = createAsyncThunk('Payment/getLedgerParty', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerParty`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getLedgerParty(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            ledgerPartyList: response1.data.body.ledgerPartyList     
        }
    }
})

//getPaymentVouNo
export const getPaymentVouNo = createAsyncThunk('Payment/getPaymentVouNo', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/payment/getPaymentVouNo`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getPaymentVouNo(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          vou_no: response1.data.body.vou_no,
          vou_date: response1.data.body.vou_date,
          statusFlag: 1    
        }
    }
})

//getPayment
export const getPayment = createAsyncThunk('Payment/getPayment', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/payment/getPayment`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getPayment(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
          paymentList: response1.data.body.paymentList,
          statusFlag:  response1.data.body.statusFlag  
        }
    }
})


// insertPayment Api
export const insertPayment = createAsyncThunk('Payment/insertPayment', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/payment/insertPayment`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(insertPayment(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// updatePayment Api
export const updatePayment = createAsyncThunk('Payment/updatePayment', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/payment/updatePayment`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(updatePayment(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// deletePayment Api
export const deletePayment = createAsyncThunk('Payment/deletePayment', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/payment/deletePayment`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(deletePayment(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

// getPaymentEditLoad Api
export const getPaymentEditLoad = createAsyncThunk('Payment/getPaymentEditLoad', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/payment/getPaymentEditLoad`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getPaymentEditLoad(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      paymentDetails:response1.data.body.paymentList
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_PaymentMode = createAsyncThunk('Payment/getLedgerClosingBalance_PaymentMode', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_paymentmode:response1.data.body.ledgerClosing
    }
  } 
})

// getLedgerClosingBalance Api
export const getLedgerClosingBalance_paidTo = createAsyncThunk('Payment/getLedgerClosingBalance_paidTo', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerClosingBalance`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerClosingBalance(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      ledgerClosing_paidto:response1.data.body.ledgerClosing
    }
  } 
})

export const paymentSlice = createSlice({
  name: 'Payment',
  initialState: {
    ledgerModeList:[],
    ledgerPartyList:[],
    statusFlag:0,
    message:'',
    paymentList: [],
    vou_no: '',
    vou_date: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    },
    handleVocucherData: (state) => {
      state.vou_no = ''
      state.vou_date = ''
      state.paymentDetails = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getLedgerMode.fulfilled, (state, action) => {
      state.ledgerModeList = action.payload.ledgerModeList     
      state.message = action.payload.message
    })
    builder.addCase(getLedgerParty.fulfilled, (state, action) => {
      state.ledgerPartyList = action.payload.ledgerPartyList     
      state.message = action.payload.message
    })
    builder.addCase(getPaymentVouNo.fulfilled, (state, action) => {
      state.vou_date = action.payload.vou_date     
      state.vou_no = action.payload.vou_no
      state.statusFlag = action.payload.statusFlag
    })
    builder.addCase(getPayment.fulfilled, (state, action) => {
      state.paymentList = action.payload.paymentList     
      state.statusFlag = action.payload.statusFlag
    })
    .addCase(insertPayment.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })    
    .addCase(updatePayment.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deletePayment.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(getPaymentEditLoad.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.paymentDetails = action.payload.paymentDetails
     state.statusFlag = action.payload.statusFlag
     state.ledgerClosing_paymentmode = ''
     state.ledgerClosing_paidto = ''
    })
    .addCase(getLedgerClosingBalance_PaymentMode.fulfilled, (state, action) => {
     state.ledgerClosing_paymentmode = action.payload.ledgerClosing_paymentmode
    })
    .addCase(getLedgerClosingBalance_paidTo.fulfilled, (state, action) => {
      state.ledgerClosing_paidto = action.payload.ledgerClosing_paidto
     })
    
  }
})

export const {
  handleStatusFlag, handleVocucherData
} = paymentSlice.actions

export default paymentSlice.reducer
