import { lazy } from 'react'

const ProfitReport = lazy(() => import('../../views/transaction/profitReport'))

const profitReportRoutes = [
  {
    element: <ProfitReport />,
    path: '/transaction/profitReport'
  }

]

export default profitReportRoutes
