// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getPayable
export const getPayable = createAsyncThunk('PayableReport/getPayable', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}
  const response1 = await axios.post(`${api.api_url}/accountsReport/getPayable`, params, config)  
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken()
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else { 
      thunkAPI.dispatch(getPayable(params))
     }
    }
  }
   if (response1 && response1.data && response1.data.body) {
        return {
            payableList: response1.data.body.payableList,
          statusFlag:  response1.data.body.statusFlag  
        }
    }
})


export const payableReportSlice = createSlice({
  name: 'PayableReport',
  initialState: {
    statusFlag:0,
    message:'',
    payableList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    }
  },
  extraReducers: builder => {  
    builder.addCase(getPayable.fulfilled, (state, action) => {
      state.payableList = action.payload.payableList     
      state.statusFlag = action.payload.statusFlag
    })   
  }
})

export const {
  handleStatusFlag
} = payableReportSlice.actions

export default payableReportSlice.reducer
