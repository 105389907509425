import { lazy } from 'react'

const LabelPrint = lazy(() => import('../../views/transaction/labelPrint'))

const labelPrintRoutes = [
  {
    element: <LabelPrint/>,
    path: '/transaction/labelPrint'
  }

]

export default labelPrintRoutes