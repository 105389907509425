import { lazy } from 'react'

const SalesTax = lazy(() => import('../../views/transaction/salesTax'))

const salesTaxRoutes = [
  {
    element: <SalesTax />,
    path: '/transaction/salesTax'
  }

]

export default salesTaxRoutes
