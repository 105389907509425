// ** Icons Import
import { Book, Save } from 'react-feather'

const getMenuData = JSON.parse(localStorage.getItem('menuDetails'))
const getmenus = getMenuData ? getMenuData.flatMap(item => item.menu_id) : []

let masters = []
if (getmenus.find(item => item === 2)) {
  masters = [
    {
      id: 'companymaster',
      title: 'Company',
      icon: <Book size={20} />,
      navLink: '/masters/company'
    },
    {
    id: 'usermaster',
    title: 'User Master',
    icon: <Book size={20} />,
    navLink: '/masters/usermaster'
  }
]
}
if (getmenus.find(item => item === 3)) {
  masters = [
    ...masters, {
    id: 'reasons',
    title: 'Reasons  ',
    icon: <Save size={20} />,
    navLink: 'transaction/reasons'
  }
]
}
if (getmenus.find(item => item === 4)) {
  masters = [
    ...masters, {
    id: 'labelPrint',
    title: 'Label Reprint',
    icon: <Book size={20} />,
    navLink: 'transaction/labelPrint'
  }
]
}
if (getmenus.find(item => item === 14)) {
  masters = [
    ...masters, {
      id: 'menuAccess',
      title: 'Menu Access',
      icon: <Book size={20} />,
      navLink: 'transaction/menuAccess'
    }
]
}

if (getmenus.find(item => item === 15)) {
masters = [
  ...masters, {
  id: 'legerMaster',
  title: 'Ledger Master',
  icon: <Book size={20} />,
  navLink: '/accounts/ledgerMaster'
}
]
}

if (getmenus.find(item => item === 16)) {
masters = [
  ...masters, {
  id: 'ledgerGroup',
  title: 'Ledger Group',
  icon: <Book size={20} />,
  navLink: '/accounts/ledgerGroup' 
}
]
}
export default [
  {
    id: 'master',
    title: 'Master',
    icon: <Book size={12} />,
    children: masters
  }    
]
