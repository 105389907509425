// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../../src/utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../../src/utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerBookList Api
export const getLedgerBookList = createAsyncThunk('/cashBankReport/getLedgerBookList', async (param, thunkAPI) => {  
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
    const response1 = await axios.post(`${api.api_url}/accountsReport/getLedgerBookList`, param, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
     if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
       } else {
        thunkAPI.dispatch(getLedgerBookList(param)) // calling same function again getting refresh token
       }     
      }
    }
    if (response1 && response1.data && response1.data.body) {
        return {
          data: response1.data.body,
          statusFlag: response1.data.body.statusFlag     
        }
    } else {
        return { statusFlag:1 }
    }   
})

//getLedgerMode
export const getLedgerMode = createAsyncThunk('cashBankReport/getLedgerMode', async (params, thunkAPI) => {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerMode`, params, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken()
        if (refresh) {
            if (Number(refresh) === invalidRefreshToken) {
                thunkAPI.dispatch(handleLogout())
            } else {
                thunkAPI.dispatch(getLedgerMode(params))
            }
        }
    }
    if (response1 && response1.data && response1.data.body) {
        return {
            ledgerModeList: response1.data.body.ledgerModeList
        }
    }
})

export const cashBankReportSlice = createSlice({
    name: 'Cash Bank Report',
    initialState: {
        ledgerModeList: [],
        ledgerBookList: [],
        statusFlag: 0,
        message: ''
    },
    reducers: {
        handleStatusFlag: (state, action) => {
            state.statusFlag = action.payload
        },
        clearStoreData: (state) => {
            state.ledgerModeList = []
            state.data = ''
        }
    },
    extraReducers: builder => {
        builder.addCase(getLedgerMode.fulfilled, (state, action) => {
            state.ledgerModeList = action.payload.ledgerModeList
            // state.message = action.payload.message
        })
        builder.addCase(getLedgerBookList.fulfilled, (state, action) => {
            state.data = action.payload.data    
            state.statusFlag = action.payload.statusFlag
          })
    }
})

export const {
    handleStatusFlag, clearStoreData
} = cashBankReportSlice.actions

export default cashBankReportSlice.reducer

