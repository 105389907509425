// ** Icons Import
import { ShoppingBag, Delete } from 'react-feather'

const getMenuData = JSON.parse(localStorage.getItem('menuDetails'))
const getmenus = getMenuData ? getMenuData.flatMap(item => item.menu_id) : []

let report = [], account_reports = []
if (getmenus.find(item => item === 9)) {
  report = [
    {
      id: 'stock',
      title: 'Stock Report  ',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/stock'
    }
  ]
}
if (getmenus.find(item => item === 10)) {
  report = [
    ...report, {
      id: 'pendingReport',
      title: 'In-Transit Pending Report',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/pendingReport'
    }
  ]
}
if (getmenus.find(item => item === 10)) {
  report = [
    ...report, {
      id: 'profitReport',
      title: 'Profit Report',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/profitReport'
    }
  ]
}
if (getmenus.find(item => item === 11)) {
  report = [
    ...report, {
      id: 'salesTax',
      title: 'Sales Tax Report',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/salesTax'
    }
  ]
}
if (getmenus.find(item => item === 12)) {
  report = [
    ...report, {
      id: 'purchaseTax',
      title: 'Purchase Tax Report',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/purchaseTax'
    }
  ]
}
if (getmenus.find(item => item === 13)) {
  report = [
    ...report, {
      id: 'salesLog',
      title: 'Sales Logs',
      icon: <ShoppingBag size={20} />,
      navLink: 'transaction/salesLog'
    }
  ]
}

//account reports
if (getmenus.find(item => item === 20)) {
  account_reports = [
    ...account_reports, {
      id: 'dayBook',
      title: 'Day Book',
      icon: <ShoppingBag size={20} />,
      navLink: '/accounts/reports/dayBook'
    }
  ]
}

if (getmenus.find(item => item === 21)) {
  account_reports = [
    ...account_reports, {
      id: 'ledgerBook',
      title: 'Ledger Book',
      icon: <ShoppingBag size={20} />,
      navLink: '/accounts/reports/ledgerBook'
    }
  ]
}

if (getmenus.find(item => item === 22)) {
  account_reports = [
    ...account_reports, {
      id: 'cashBankReport',
      title: 'Cash Bank Report',
      icon: <ShoppingBag size={20} />,
      navLink: '/accounts/reports/cashBankReport'
    }
  ]
}

if (getmenus.find(item => item === 23)) {
  account_reports = [
    ...account_reports, {
      id: 'payableReport',
      title: 'Payable Report',
      icon: <ShoppingBag size={20} />,
      navLink: '/accounts/reports/payableReport'        
    }
  ]
}

if (getmenus.find(item => item === 24)) {
  account_reports = [
    ...account_reports, {
      id: 'receivableReport',
      title: 'Receivable Report',
      icon: <ShoppingBag size={20} />,
      navLink: '/accounts/reports/receivableReport'
    }
  ]
}

let reports = []
if (report && report.length > 0) {
  reports = [
    ...reports, {
      id: 'transactions_report',
      title: 'Transactions',
      icon: <ShoppingBag size={20} />,
      children: report
    }
  ]
}

if (account_reports && account_reports.length > 0) {
  reports = [
    ...reports, {
      id: 'accounts_report',
      title: 'Accounts',
      icon: <ShoppingBag size={20} />,
      children: account_reports
    }
  ]
}

export default [
  {
    id: 'report',
    title: 'Report',
    icon: <ShoppingBag size={12} />,
    children: reports
  }   
]
