// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../../src/utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../../src/utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerGroupList Api
export const getLedgerGroupList = createAsyncThunk('/LedgerGroupMaster/getLedgerGroupList', async (param, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerGroupList`, param, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
        } else {
          thunkAPI.dispatch(getLedgerGroupList(param)) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      return {
        data: response1.data.body.ledgerGroupList,
        statusFlag: 1    
      }
    } else {
      return { statusFlag: 1 }
    }
  } catch (error) {
    console.error('Error In Ledger Group List API', error)
    throw error
  }
})

// saveLedgerGroup API
export const saveLedgerGroup = createAsyncThunk('LedgerGroupMaster/saveLedgerGroup', async ({ user_id, group_name, account_type_code }) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/saveLedgerGroup`, { user_id, group_name, account_type_code }, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
        } else {
          thunkAPI.dispatch(saveLedgerGroup(params)) // calling same function again getting refresh token
        }
      }
    }
    return response1.data.body
  } catch (error) {
    console.error('Error saving Ledger Group API:', error)
    throw error
  }
})

//delete Api 
export const deleteLedgerGroup = createAsyncThunk('LedgerGroupMaster/deleteLedgerGroup', async (params, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/deleteLedgerGroup`, params, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
        } else {
          thunkAPI.dispatch(deleteLedgerGroup(params)) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag: response1.data.body.statusFlag,
        message: response1.data.body.message
      }
    }
  } catch (error) {
    console.error('error In Delete Ledger Group', error)
    throw error
  }

})

//updateLedgerGroup Api 
export const updateLedgerGroup = createAsyncThunk('accountsMaster/updateLedgerGroup', async (params, thunkAPI) => {
  try {
    const user = getUserData()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(`${api.api_url}/accountsMaster/updateLedgerGroup`, params, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === invalidRefreshToken) {
          thunkAPI.dispatch(handleLogout())
        } else {
          thunkAPI.dispatch(updateLedgerGroup(params)) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag: response1.data.body.statusFlag,
        message: response1.data.body.message
      }
    }
  } catch (error) {
    console.error('Error in Update Ledger Group API', error)
    throw error
  }

})

export const LedgerGroupMasterSlice = createSlice({
  name: 'LedgerGroupMaster',
  initialState: {
    data: [],
    params: {},
    statusFlag: 0,
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getLedgerGroupList.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.statusFlag = action.payload.statusFlag
    })
      .addCase(deleteLedgerGroup.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
      .addCase(updateLedgerGroup.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
      .addCase(saveLedgerGroup.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
  }
})

export const {
  handleStatusFlag
} = LedgerGroupMasterSlice.actions

export default LedgerGroupMasterSlice.reducer