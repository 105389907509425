// ** Icons Import
import { Book, Save, Trello } from 'react-feather'

const getMenuData = JSON.parse(localStorage.getItem('menuDetails'))
const getmenus = getMenuData ? getMenuData.flatMap(item => item.menu_id) : []

let vouchers = []
if (getmenus.find(item => item === 17)) {
  vouchers = [  
    ...vouchers, {
      id: 'payment',
      title: 'Payment',
      icon: <Book size={20} />,
      navLink: '/accounts/payment'
    }
]
}
if (getmenus.find(item => item === 18)) {
  vouchers = [      
  ...vouchers, {
      id: 'receipt',
      title: 'Receipt',
      icon: <Book size={20} />,
      navLink: '/accounts/receipt'
    }
  ]
}
if (getmenus.find(item => item === 19)) {
  vouchers = [  
    ...vouchers, {
      id: 'contra',
      title: 'Contra',
      icon: <Book size={20} />,
      navLink: '/accounts/contra'
    }
  ]
}


export default [
  {
    id: 'vouchers',
    title: 'Vouchers',
    icon: <Trello size={12} />,
    children: vouchers
  }    
]
