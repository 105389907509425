

import axios from 'axios'
import { api, invalidRefreshToken } from '../utility/constants'
import { getUserData } from '@utils'
// ** Returns paginated array
export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

// ** Returns sorted array
export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

// ** Returns number range
export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

// ** Returns random date
export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const getMyIpAddress = async() => {
  const res = await axios.get('https://ipapi.co/json/')
  return res.data.ip
}

//getRefreshToken 
export const getRefreshToken = async() => {
  const user = getUserData()
  const params = { token: user.refreshToken }
  const response1 = await axios.post(`${api.api_url}/login/refreshToken`, params)
  if (response1?.data?.body?.tokenReult) {
    if (response1?.data?.body?.tokenReult?.status === invalidRefreshToken) {      
      return invalidRefreshToken
    } 
    const accessToken = response1?.data?.body?.tokenReult?.accessToken
    const refreshToken = response1?.data?.body?.tokenReult?.refreshToken
    const data = Object.assign({}, user)
    data.accessToken = accessToken ? accessToken : data.accessToken
    data.refreshToken = refreshToken ? refreshToken : data.refreshToken
    localStorage.setItem('userDetails', JSON.stringify(data))    
    return accessToken
  } else {
    return ''
  }
}