// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//Update Company Api 
export const updateCompany = createAsyncThunk('master/updateCompany', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
  const response1 = await axios.post(`${api.api_url}/master/updateCompany`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
    if (refresh) {
      if (Number(refresh) === invalidRefreshToken) {
        thunkAPI.dispatch(handleLogout())
      } else {
        thunkAPI.dispatch(deleteInward(params)) // calling same function again getting refresh token
      }
    }
  }
  if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag: response1.data.body.statusFlag,
      message: response1.data.body.message
    }
  }
})

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    statusFlag: 0,
    message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
  }
})
export const {
  handleStatusFlag
} = companySlice.actions

export default companySlice.reducer