// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import UsersData from '@src/views/masters/userMaster/store'
import Process from '@src/views/process/store'
import Inward from '@src/views/transaction/inward/store'
import ProductCancel from '@src/views/transaction/productCancel/store'
import Sales from '@src/views/transaction/sales/store'
import SalesReturn from '@src/views/transaction/salesReturn/store'

import reasons from '@src/views/transaction/Reasons/store'
import mobileView from '@src/views/transaction/mobileView/store'
import labelPrint from '@src/views/transaction/labelPrint/store'
import inwardDelete from '@src/views/transaction/inwardDelete/store'
import menuAccess from '@src/views/transaction/menuAccess/store'
import pendingReport from '@src/views/transaction/pendingReport/store'
import profitReport from '@src/views/transaction/profitReport/store'
import salesTax from '@src/views/transaction/salesTax/store'
import purchaseTax from '@src/views/transaction/purchaseTax/store'
import salesLog from '@src/views/transaction/salesLog/store'
import stockreport from '@src/views/transaction/stock/store'
import LedgerMaster from "@src/views/accounts/masters/ledgerMaster/store"
import Company from "@src/views/masters/company/store"
import LedgerGroupMaster from "@src/views/accounts/masters/ledgerGroupMaster/store"
import Payment from "@src/views/accounts/payment/store"
import Receipt from "@src/views/accounts/receipt/store"
import Contra from "@src/views/accounts/contra/store"
import DayBookReport from "@src/views/accounts/reports/dayBook/store"
import LedgerBookReport from "@src/views/accounts/reports/ledgerBook/store"
import CashBankReport from "@src/views/accounts/reports/cashBankReport/store"
import ReceivableReport from "@src/views/accounts/reports/receivableReport/store"
import PayableReport from "@src/views/accounts/reports/payableReport/store"

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  UsersData,
  Process,
  Inward,
  ProductCancel,
  Sales,
  SalesReturn,
  reasons,
  mobileView,
  labelPrint,
  inwardDelete,
  menuAccess,
  salesTax,
  purchaseTax,
  salesLog,
  stockreport,
  pendingReport,
  profitReport,
  LedgerMaster,
  Company,
  Payment,
  Receipt,
  Contra,
  DayBookReport,
  LedgerBookReport,
  CashBankReport,
  LedgerGroupMaster,
  ReceivableReport,
  PayableReport
}

export default rootReducer
