// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRefreshToken } from '../../../../../utility/commonfunc'
// ** Axios Imports
import axios from 'axios'
import { api, invalidRefreshToken } from '../../../../../utility/constants'
import { getUserData } from '@utils'
import { handleLogout } from '@store/authentication'

//getLedgerGroupList Api
export const getLedgerGroupList = createAsyncThunk('LedgerMaster/getLedgerGroupList', async (param, thunkAPI) => {  
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerGroupList`, param, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerGroupList(param)) // calling same function again getting refresh token
     }     
    }
  }
  if (response1 && response1.data && response1.data.body) {        
      return {
        ledgerGroupList: response1.data.body.ledgerGroupList,
        statusFlag: 1     
      }
  } else {
      return { statusFlag:1 }
  }   
})

//getLedgerList Api
export const getLedgerList = createAsyncThunk('LedgerMaster/getLedgerList', async (param, thunkAPI) => {  
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/getLedgerList`, param, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(getLedgerList(param)) // calling same function again getting refresh token
     }     
    }
  }
  if (response1 && response1.data && response1.data.body) {        
      return {
        ledgerList: response1.data.body.ledgerList,
        statusFlag: 1     
      }
  } else {
      return { statusFlag:1 }
  }   
})


// saveLedger Api
export const saveLedger = createAsyncThunk('LedgerMaster/saveLedger', async (params, thunkAPI) => {
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}  
  const response1 = await axios.post(`${api.api_url}/accountsMaster/saveLedger`, params, config)
  if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
      thunkAPI.dispatch(saveLedger(params)) // calling same function again getting refresh token
     }
    }
  }
  if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
  } 
})

//deleteLedger Api 
export const deleteLedger = createAsyncThunk('LedgerMaster/deleteLedger', async (params, thunkAPI) => { 
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}       
   const response1 = await axios.post(`${api.api_url}/accountsMaster/deleteLedger`, params, config)
   if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
       thunkAPI.dispatch(deleteLedger(params)) // calling same function again getting refresh token
     }
    }
  }
   if (response1 && response1.data && response1.data.body) { 
    return {     
      statusFlag:response1.data.body.statusFlag,      
      message:response1.data.body.message
    }   
  } 
})

//updateLedger Api 
export const updateLedger = createAsyncThunk('LedgerMaster/updateLedger', async (params, thunkAPI) => { 
  const user = getUserData()
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) }}       
   const response1 = await axios.post(`${api.api_url}/accountsMaster/updateLedger`, params, config)
   if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
    const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
   if (refresh) {
    if (Number(refresh) === invalidRefreshToken) {
      thunkAPI.dispatch(handleLogout())
     } else {
       thunkAPI.dispatch(updateLedger(params)) // calling same function again getting refresh token
     }
    }
  }
   if (response1 && response1.data && response1.data.body) { 
    return {     
      statusFlag:response1.data.body.statusFlag,      
      message:response1.data.body.message
    }   
  } 
})

export const LedgerMasterSlice = createSlice({
  name: 'LedgerMaster',
  initialState: {
    userroleList:[],
    statusFlag:0,
    message:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getLedgerList.fulfilled, (state, action) => {
      state.ledgerList = action.payload.ledgerList
      state.statusFlag = action.payload.statusFlag
    })
    .addCase(getLedgerGroupList.fulfilled, (state, action) => {
      state.ledgerGroupList = action.payload.ledgerGroupList
    })   
    .addCase(saveLedger.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })    
    .addCase(updateLedger.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteLedger.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    
  }
})

export const {
  handleStatusFlag
} = LedgerMasterSlice.actions

export default LedgerMasterSlice.reducer
