// ** Icons Import
import { ShoppingCart, Delete } from 'react-feather'

const getMenuData = JSON.parse(localStorage.getItem('menuDetails'))
const getmenus = getMenuData ? getMenuData.flatMap(item => item.menu_id) : []

let trans = []
if (getmenus.find(item => item === 5)) {
  trans = [
    {
      id: 'inward',
      title: 'Stock Inward  ',
      icon: <ShoppingCart size={20} />,
      navLink: 'transaction/inward'
    },
    {
      id: 'inwardDelete',
      title: 'Inward Delete ',
      icon: <Delete size={20} />,
      navLink: 'transaction/inwardDelete'
    }
  ]
}
if (getmenus.find(item => item === 6)) {
  trans = [
    ...trans,
    {
      id: 'import',
      title: 'Cancelled Product',
      icon: <Delete size={20} />,
      navLink: 'product/cancelledproducts'
    }
  ]
}

export default [
  {
    id: 'transactions',
    title: 'Transactions',
    icon: <ShoppingCart size={12} />,
    children: trans
  }   
  
]
